import { loadStripe } from "@stripe/stripe-js";

let stripePromise
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
  }
  return stripePromise
}

export const aggregateLineItems = (priceQuantityCollection) => {
  var lineItems = [];
  priceQuantityCollection.reduce(function (res, value) {

    if (!res[value.price]) {
      res[value.price] = { price: value.price, quantity: 0 };
      lineItems.push(res[value.price])
    }

    res[value.price].quantity += value.quantity;

    return res;
  }, {});
  return lineItems;
}
