const metadataFromCartItem = (item) => {
    return {
        id: item.id,
        name: item.name,
        description: item.description,
        type: item.type,
        imageFile: item.imageFile.publicURL,
        code: item.code,
        set1ColourLabel: item.set1ColourLabel,
        set2ColourLabel: item.set2ColourLabel,
        set3ColourLabel: item.set3ColourLabel,
        set4ColourLabel: item.set4ColourLabel,
        set5ColourLabel: item.set5ColourLabel,
        set6ColourLabel: item.set6ColourLabel,
        set7ColourLabel: item.set7ColourLabel,
        set8ColourLabel: item.set8ColourLabel,
        set9ColourLabel: item.set9ColourLabel,
        set10ColourLabel: item.set10ColourLabel,
        selectedPriceId: item.selectedPriceId,
        selectedSize: item.selectedSize,
        selectedPrice: item.selectedPrice,
        selectedFlavour: item.selectedFlavour,
        selectedTexture: item.selectedTexture,
        selectedCakeShape: item.selectedCakeShape,
        selectedDecoration: item.selectedDecoration,
        selectedSet1Colour: item.selectedSet1Colour,
        selectedSet2Colour: item.selectedSet2Colour,
        selectedSet3Colour: item.selectedSet3Colour,
        selectedSet4Colour: item.selectedSet4Colour,
        selectedSet5Colour: item.selectedSet5Colour,
        selectedSet6Colour: item.selectedSet6Colour,
        selectedSet7Colour: item.selectedSet7Colour,
        selectedSet8Colour: item.selectedSet8Colour,
        selectedSet9Colour: item.selectedSet9Colour,
        selectedSet10Colour: item.selectedSet10Colour,
        cakeTopperMessage: item.cakeTopperMessage,
        cakeTopperType: item.cakeTopperType,
        selectedDietaryOptionIsDF: item.selectedDietaryOptionIsDF,
        selectedDietaryOptionIsV: item.selectedDietaryOptionIsV,
        selectedDietaryOptionIsGF: item.selectedDietaryOptionIsGF,
        key: item.key,
        quantity: item.quantity
    }
}

export const metadataFromCartItems = (items) => {
    return items.map(metadataFromCartItem)
}